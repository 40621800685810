 const host = window.location.host
// const host = "colven.ipcallgo.com"
// const host = "crmlte.ipcallgo.com"
console.log("host: ", host);

const dev = {
	API_ENDPOINT_URL:      'https://'+host+'/api',
	STATIC_ENDPOINT_URL:  'https://'+host+'/api/static',
	//API_ENDPOINT_URL:     'http://localhost:7001/api',
	//STATIC_ENDPOINT_URL:  'https://localhost:7001/api/static',

	LOGO_PATH: 			  '/img',
	MAIL_BASE_URL :       "https://mail.ipcall.com.ar/v1",
	MAIL_BASE_PROXY_URL:  "https://mail.ipall.com.ar:3001/proxi?url=v1",

	//CHAT_API_URL :        "http://192.168.100.58:4446/api",
	//CHAT_BASE_URL :       "http://192.168.100.58:4446/ipchat",
	//CHAT_API_URL :        "http://localhost:4446/api",
	//CHAT_BASE_URL :       "https://localhost:4446/ipchat",
	CHAT_API_URL :        "https://"+host+"/ipchat/api",
	CHAT_BASE_URL :       "https://"+host+'/ipchat',

	MAIL_SOCKET :    'https://mail-socket.ipcall.com.ar',
	//CHAT_SOCKET :    'http://192.168.100.58:4445/',
	CHAT_SOCKET :    'https://'+host,
	CONSOLA_SOCKET : 'https://'+host,

	CONSOLA_API_URL : 'https://'+host+'/consola',

	FACEBOOK_APP_ID :'1150719708930100',
	GOOGLE_API_CLIENT_ID :'6417990840-6q2k2trjikti5mrst2e5ofllivngc9m9.apps.googleusercontent.com',
	NOTIFICACIONES_SOCKET :'',
	HOST_URL: 'https://'+host,
	HOST: host,
};

const prod = {
  	API_ENDPOINT_URL:     'https://'+host+'/api',
	STATIC_ENDPOINT_URL:  'https://'+host+'/api/static',
	LOGO_PATH: 			  'https://'+host+'/imagenes',
	MAIL_BASE_URL :       "https://mail.ipcall.com.ar/v1",
	MAIL_BASE_PROXY_URL:  "https://mail.ipall.com.ar:3001/proxi?url=v1",

	CHAT_API_URL :        "https://"+host+"/ipchat/api",
	CHAT_BASE_URL :       "https://"+host+'/ipchat',

	MAIL_SOCKET :    'https://'+host+'/socket/mail',
	CHAT_SOCKET :    'https://'+host,
	CONSOLA_SOCKET : 'https://'+host,

	CONSOLA_API_URL : 'https://'+host+'/consola',

	FACEBOOK_APP_ID :'1150719708930100',
	GOOGLE_API_CLIENT_ID :'6417990840-6q2k2trjikti5mrst2e5ofllivngc9m9.apps.googleusercontent.com',
	NOTIFICACIONES_SOCKET :'',
	HOST_URL: 'https://'+host,
	HOST: host,
};

const test = {
	
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
