import React  from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'

const PublicRoute = () => {

	const allowedPublicRoutes = ['/terminos-y-condiciones','/politicas-privacidad'];
	const { token } = useSelector(state => state.auth)
	const location = useLocation();

	if (token && !allowedPublicRoutes.includes(location.pathname)) {
        return <Navigate to={AUTHENTICATED_ENTRY} />;
    }
  
	return <Outlet />;
}

export default PublicRoute